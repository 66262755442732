import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])

export default function sliders() {
	;(function ($) {
		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.video-gallery-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,

					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
					thumbs: {},
					spaceBetween: 10,
                    slidesPerView: 1,
					// breakpoints: {
					// 	640: {
					// 		slidesPerView: 2,
					// 		spaceBetween: 20,
					// 	},
					// 	768: {
					// 		slidesPerView: 2,
					// 		spaceBetween: 30,
					// 	},
					// 	1024: {
					// 		slidesPerView: 2,
					// 		spaceBetween: 40,
					// 	},
					// },
				}

				// init slider
				new Swiper(el, options)
			})
		})

		$(window).on('load', function () {
			// get all sliders, we need to loop them due to different settings + nav
			var swipers = document.querySelectorAll('.review-gallery-swiper')
			swipers.forEach(function (el, index) {
				var closestSection = el.closest('section')
				var controls = closestSection.querySelector('.control')

				// slider settings
				var options = {
					speed: 600,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					thumbs: {},
					autoHeight: true,
					spaceBetween: 5,
                    slidesPerView: 1,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 10,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 15,
						},
						1024: {
							slidesPerView: 2,
							spaceBetween: 18,
						},
					},
				}

				// init slider
				new Swiper(el, options)
			})
		})
	})(jQuery)
}
