// import range from './range.js'
import contentLoaded from './vendor/contentloaded.min.js'
import './vendor/jquery.viewportchecker.min.js'
import './vendor/jquery.easing.min'
import validate from './vendor/jquery.validate.min.js'
import accordion from './accordion.js'
import images from './images.js'
import multistep from './multi-step-form.js'
import phoneConcat from './phone-concatination.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import smoothScroll from './smooth-scroll.js'
import stickyHeader from './sticky-header.js'
import timer from './timer.js'
import videos from './video-embeds.js'
import viewAnimation from './view-animation.js'
import getCurrentMonth from './month.js'
import kkThankYou from './thank-you.js'
import initModal from './initModal.js'
import hubspotForms from './hubspot-forms.js'
import { rangeSliderInit, runRangeSlider } from './vendor/rangeslider.js'
contentLoaded()

// easing()
//validate()

window.contentLoaded(window, function (e) {
	rangeSliderInit()
	runRangeSlider()
	polyfill()
	kkThankYou()
	phoneConcat()
	images()
	getCurrentMonth()
	sliders()
	smoothScroll()
	stickyHeader()
	videos()
	viewAnimation()
	getCurrentMonth()
	initModal()
	multistep()
	// hubspotForms()
	//accordion()
	//timer()
})